<template>
  <div class="accountset enterpriselist">
    <userheader menucurrent="2" />
    <div class="main-div">
      <div class="account-content wrap">
        <userleft currindex="12" />
        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="title">面试邀请<em></em></h3>
              <div class="info-cnt">
                <div class="content-rb">
                  <el-tabs type="border-card" v-model="activeName">
                    <el-tab-pane label="面试邀请列表" name="one">
                      <div v-if="getmyinterviewlist.length >= 1">
                        <el-table
                          :data="getmyinterviewlist"
                          border
                          style="width: 100%"
                        >
                          <el-table-column
                            fixed
                            prop="interviewDate"
                            label="面试日期"
                            align="center"
                            width="200"
                          ></el-table-column>
                          <el-table-column
                            label="面试时间段"
                            align="center"
                            width="100"
                          >
                            <template slot-scope="scope">
                              <span>{{
                                scope.row.oclockStart +
                                ':00' +
                                '~' +
                                scope.row.oclockEnd +
                                ':00'
                              }}</span>
                            </template>
                          </el-table-column>
                          <el-table-column
                            prop="address"
                            label="面试地点"
                            align="center"
                            width="150"
                          ></el-table-column>
                          <el-table-column
                            prop="contacts"
                            label="联系人"
                            align="center"
                            width="100"
                          ></el-table-column>
                          <el-table-column
                            prop="contactsPhone"
                            label="联系电话"
                            align="center"
                            width="120"
                          ></el-table-column>
                          <el-table-column
                            fixed="right"
                            label=""
                            align="center"
                            width="200"
                          >
                            <template slot-scope="scope">
                              <div class="setasdefault">
                                <el-popconfirm
                                  @confirm="oncancel(scope.row.id)"
                                  title="确定取消吗"
                                >
                                  <el-button
                                    type="danger"
                                    size="small"
                                    slot="reference"
                                    plain
                                    >拒绝面试邀请</el-button
                                  >
                                </el-popconfirm>
                                <el-button
                                  type="primary"
                                  class="infoBtn"
                                  size="small"
                                  plain
                                  @click="showInfo(scope.row.id)"
                                  >详情</el-button
                                >
                              </div>
                            </template>
                          </el-table-column>
                        </el-table>
                        <el-pagination
                          class="centerPagination"
                          layout="prev, pager, next"
                          :total="total"
                        >
                        </el-pagination>
                      </div>
                      <div v-if="getmyinterviewlist.length == 0">
                        <div class="empty">
                          <i class="icon el-icon-sunrise"></i>
                          <span>暂无信息</span>
                        </div>
                      </div>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <userfooter />
    <!-- 面试详情弹框 -->
    <el-dialog title="面试详情" :visible.sync="dialogVisible" width="30%">
      <div class="InfoBox">
        <el-row type="flex" class="row-bg" justify="center">
          <el-col :span="9">公司名称：</el-col>
          <el-col :span="9">{{ interviewdetai.companyName }}</el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="center">
          <el-col :span="9">职位名称：</el-col>
          <el-col :span="9">{{ interviewdetai.positionName }}</el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="center">
          <el-col :span="9">面试日期：</el-col>
          <el-col :span="9">{{ interviewdetai.interviewDate }}</el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="center">
          <el-col :span="9">面试时间：</el-col>
          <el-col :span="9"
            >{{ interviewdetai.oclockStart }}:00~{{
              interviewdetai.oclockEnd
            }}:00</el-col
          >
        </el-row>
        <el-row type="flex" class="row-bg1" justify="center">
          <el-col :span="9">面试地点：</el-col>
          <el-col :span="9">{{ interviewdetai.address }}</el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="center">
          <el-col :span="9">联系人：</el-col>
          <el-col :span="9">{{ interviewdetai.contacts }}</el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="center">
          <el-col :span="9">联系电话：</el-col>
          <el-col :span="9">{{ interviewdetai.contactsPhone }}</el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="center">
          <el-col :span="9">面试状态：</el-col>
          <el-col :span="9" v-if="interviewdetai.interviewStatus == 0"
            >未面试</el-col
          >
          <el-col :span="9" v-else>已面试</el-col>
        </el-row>
        <el-row type="flex" class="row-bg" justify="center">
          <el-col :span="9">是否拒绝面试邀请：</el-col>
          <el-col :span="9" v-if="interviewdetai.refuseStatus == 0">否</el-col>
          <el-col :span="9" v-else>是</el-col>
        </el-row>
        <el-row
          type="flex"
          class="row-bg2"
          justify="center"
          v-if="
            interviewdetai.resultStatus == 1
              ? 'true'
              : interviewdetai.resultStatus == 0
              ? 'true'
              : 'false'
          "
        >
          <el-col :span="9">面试结果</el-col>
          <el-col :span="9" v-if="interviewdetai.resultStatus == 1"
            >通过</el-col
          >
          <el-col :span="9" v-else>拒绝</el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import Cookies from 'js-cookie';
import {getCookieByName} from '@/utils/helper';
var token = getCookieByName('access_token');
import {
  getmyinterviewlist,
  refuseinterview,
  getinterviewdetai,
} from '../../api/jobwant';
import userleft from '@/components/userleft.vue';
export default {
  name: 'App',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft,
  },
  data() {
    return {
      param: {
        recruitId: 0,
        interviewStatus: null,
        refuseStatus: null,
        resultStatus: null,
        keyword: '',
        pageIndex: 1,
        pageSize: 10,
      },
      getmyinterviewlist: [],
      activeName: 'one',
      total: '',
      dialogVisible: false,
      interviewdetai: {},
    };
  },
  created() {},
  mounted() {
    this.onGetmyinterviewlist();
  },
  methods: {
    // 我的求职申请列表数据
    async onGetmyinterviewlist() {
      let param = this.param;
      const { data: res } = await getmyinterviewlist(param);
      console.log(res);
      if (res.code === 1) {
        this.getmyinterviewlist = res.result.list;
        this.total = res.result.totalPage;
      }
    },
    // 拒绝面试邀请
    async oncancel(id) {
      const { data: res } = await refuseinterview(id);
      if (res.code === 1) {
        this.$message.success('您拒绝了面试邀请');
      } else {
        this.$message.error(res.message);
      }
    },
    async showInfo(id) {
      this.dialogVisible = true;
      const { data: res } = await getinterviewdetai(id);
      this.interviewdetai = res.result;
      console.log(res);
    },
  },
};
</script>
<style  scoped >
/**公共css开始**/
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.acount-card {
  background-position: 0 -172px;
  background-image: url('../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 940px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}
.accountset .content-rb .empty {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
.accountset .content-rb .empty i {
  font-size: 40px;
  color: #ddd;
  width: 100%;
  display: inline-block;
}
.accountset .content-rb .empty span {
  width: 100%;
  display: inline-block;
  font-size: 16px;
  color: #ddd;
}
.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
}
.accountset >>> form.elform {
  width: 460px;
}
/**公共css结束**/
.accountset .content-rb .btnaddcompany {
  position: absolute;
  right: 36px;
  z-index: 100;
}
.accountset .content-rb .btnaddcompany button {
  padding: 6px 6px;
  margin-top: 6px;
}
.accountset .content-rb .btnaddcert button {
  margin: 10px 0;
  padding: 6px 6px;
}
.accountset .content-rb .property-box {
  margin-bottom: 20px;
  background-image: linear-gradient(196deg, #72b2ff, #0074ff);
  border-radius: 4px;
}

.operation,
.setasdefault {
  cursor: pointer;
}
/* .setasdefault >>> .el-button:hover,
.setasdefault >>> .el-button {
  color: #f56c6c;
  background: #fef0f0;
  border-color: #fbc4c4;
} */
.operation .vertical {
  cursor: default;
  margin: 0 6px;
}
.centerPagination {
  margin-left: 45%;
  margin-top: 30px;
}
.infoBtn {
  margin-left: 10px;
}
.row-bg {
  height: 30px;
  border-bottom: 1px solid#ccc;
  margin-bottom: 20px;
  margin-top: 20px;
}
.row-bg1 {
  min-height: 30px;
  border-bottom: 1px solid#ccc;
  margin-bottom: 20px;
  margin-top: 20px;
}
.row-bg2 {
  min-height: 30px;
  margin-top: 20px;
}
.InfoBox {
  width: 400px;
  border: 1px solid #ccc;
  margin-left: 50px;
}
</style>
